@import "src/assets/scss/vars";
@import "src/assets/scss/breakpoints";

.container {
  width: 100vw;
  background: #141333;
}

.copyright {
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: $primary_border;
  & > span {
    display: block;
    @include respond-to(m) {
      display: inline;
    }
  }
  @include respond-to(m) {
    font-size: 12px;
  }
}
