@import "../../assets/scss/breakpoints";
@import "../../assets/scss/mixins";
@import "../../assets/scss/vars";

.container {
  background: $background_gradient;
  overflow: hidden;
}

.app {
  @include flex(column, space-between);
  max-width: 1366px;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: border-box;
  @include respond-to(m) {
    padding: 0 34px
  }
  @include respond-to(l) {
    padding: 0 50px
  }
}

.content {
  flex-grow: 1;
  display: flex;
  align-self: center;
  width: 100%;

  & > div {
    width: 100%;
    @include flex(column, space-between);
    @include respond-to(l) {
      justify-content: flex-start
    }
  }
}
